import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, takeUntil } from 'rxjs';
import { TableExportExcelConstants } from 'src/app/common/constants';
import { ITableColumn, PropertyBag, TableFilters } from 'src/app/common/models';
import { dateFormat, processEndDateForTableFilter, processStartDateForTableFilter } from 'src/app/common/utils';
import { ExportExcelService } from 'src/app/report-center/services/export-excel.service';
import { WorkshopConstants } from 'src/app/workshop/constants';
import { EquipmentTestResultsActions } from 'src/app/workshop/state/actions/equipment-test-results';
import { tableColumns } from './table-columns';
import { readModelSortKeys, tableCustomFiltersLayout, tableDefaultSorting } from './table-settings';
import { ExportTableExcelService, LoggerService, NotificationsService } from '../../../../common/services';
import { ITestResult, ITestResultDetails, ITestResultPage } from '../../../models';
import { selectTestResultsById, selectTestResultsPage } from '../../../state/selectors';

import {
  ApplicationState,
  EquipmentTaskResultsTableFiltersConstants,
  IStoreApiItem,
  StorageConstants,
  TableHelperComponent,
} from 'src/app/common';

/* eslint-disable no-prototype-builtins */
@Component({
  selector: 'ignis-task-documentation-list',
  templateUrl: './task-documentation-list.component.html',
  styleUrls: ['.//task-documentation-list.component.scss'],
})
export class TaskDocumentationListComponent extends TableHelperComponent implements OnInit {
  @Input() formatDate: string;

  tableName: string = 'task-documentation';
  localTableColumns: ITableColumn[] = tableColumns;
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;
  customFilterLayouts: string[] = tableCustomFiltersLayout;
  defaultSorting: string = tableDefaultSorting;
  readModelSortKeys: PropertyBag = readModelSortKeys;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.SERVICE_RESULTS;
  excelTranslationKeys: PropertyBag = TableExportExcelConstants.testResultsSheetTranslationKeys;
  list: ITestResult[];
  selectedTestResult: ITestResult;
  tableExportList: ITestResult[] = [];

  router: Router = inject(Router);
  loggerService: LoggerService = inject(LoggerService);
  exportExcelService: ExportExcelService = inject(ExportExcelService);
  equipmentTestResultsActions: EquipmentTestResultsActions = inject(EquipmentTestResultsActions);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  exportTableExcelService: ExportTableExcelService = inject(ExportTableExcelService);

  constructor(private store: Store<ApplicationState>) {
    super();

    this.tableHelperReadSavedFiltersValues(
      EquipmentTaskResultsTableFiltersConstants.selectedDropdownsFilterValues,
      EquipmentTaskResultsTableFiltersConstants.selectedDatesFilterValues,
    );
  }

  ngOnInit(): void {
    this.readTestResultData();
  }

  readTestResultData(): void {
    this.isLoading$ = combineLatest([
      this.store.select(selectTestResultsPage),
      this.store.select(selectTestResultsById),
    ]).pipe(
      map(
        ([testResultsPageState, testResultsByIdState]: [
          IStoreApiItem<ITestResultPage>,
          IStoreApiItem<ITestResultDetails>,
        ]) => testResultsPageState.isLoading || testResultsByIdState.isLoading,
      ),
      takeUntil(this.destroy),
    );

    this.store
      .pipe(
        select(selectTestResultsPage),
        map((state: IStoreApiItem<ITestResultPage>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: ITestResultPage) => {
        if (response) {
          this.list = structuredClone(response.entries);
          this.totalRecords = response.totalRecords;
          this.totalPages = response.totalPages;

          this.tableHelperCheckOutOfRangePageFilter();

          this.list.forEach((testResult: ITestResult) => {
            if (testResult.dateTime) {
              testResult.dateTime = dateFormat(new Date(testResult.dateTime).toString(), this.formatDate);
            }
          });
        }
      });
  }

  fetchTableData(params: PropertyBag): void {
    this.equipmentTestResultsActions.requestTestResultsPage(params, this.isExportMode);
  }

  onTestResultSelect(testResult: ITestResult): void {
    this.selectedTestResult = testResult;
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableHelperResetTablePaging();

    columns.forEach((column: ITableColumn) => {
      if (!column.visible) {
        if (this.tableHelperCheckForSorting(column.field)) {
          this.tableHelperResetTableSorting();
        }

        this.tableHelperHideAndResetTableColumn(this.localTableColumns, column);
      }
    });

    this.tableHelperReadSavedFiltersValues(
      EquipmentTaskResultsTableFiltersConstants.selectedDropdownsFilterValues,
      EquipmentTaskResultsTableFiltersConstants.selectedDatesFilterValues,
    );
    this.tableHelperSaveTableSettings(columns);
    this.tableHelperSetAllTableFilters(this.filtersData);

    this.tableHelperPrepareTableParameters();
  }

  getFilterTableValue(event: TableFilters): void {
    if ('testerName' in event) {
      event.tester = event.testerName as string;
    } else if ('operationalStatus' in event) {
      event.functional = event.operationalStatus as string;
    } else if ('task' in event) {
      event.taskName = event.task as string;
    } else if ('dateTime' in event) {
      event = {
        ...this.processTestDateFilter(event),
      };
    }

    Object.assign(this.filtersData, event);

    this.tableHelperSetAllTableFilters(this.filtersData);
  }

  /* istanbul ignore next */
  processTestDateFilter(event: TableFilters): TableFilters {
    const checkEndTime: Date = event.dateTime[1] ? new Date(Object.values(event)[0][1] as string) : null;

    if (Object.values(event)[0] === 'reset-date') {
      delete this.filtersData.startTime;
      delete this.filtersData.endTime;
      delete this.filtersData.dateTime;
      delete event.dateTime;

      this.tableHelperSetAllTableFilters({
        startTime: null,
        endTime: null,
        dateTime: null,
      });

      return {};
    } else {
      return {
        ...this.tableService.tableDateParameter(
          processStartDateForTableFilter(new Date(Object.values(event)[0][0] as string)),
          'startTime',
        ),
        ...this.tableService.tableDateParameter(processEndDateForTableFilter(checkEndTime), 'endTime'),
      };
    }
  }

  onTestViewResult(): void {
    this.router.navigate(['workshop', 'task-result', this.selectedTestResult.aggregateId]);
  }

  exportExcelFile(): void {
    this.isExportMode = true;

    this.exportExcelService.genericExport(this.getDataForExport, this.preparationForExport).then((data: unknown[]) => {
      this.excelArray = data;
      this.isExportMode = false;
    });
  }

  preparationForExport = (test: ITestResult): void => {
    test.operationalStatus = this.translateOperationalStatus(test.operationalStatus);
    test.dateTime = this.exportTableExcelService.formatExcelDataToLocal(test.dateTime, this.formatDate);
  };

  getDataForExport = (page: number = 0): void => {
    this.tableHelperPrepareTableParameters(page);
  };

  translateOperationalStatus(status: string): string {
    return this.translateService.instant(WorkshopConstants.operationalStatusTranslationKey[status]);
  }
}
